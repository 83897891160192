<template>
  <v-card
    hover
    height="100%"
    :ripple="false"
    @click="openCourse(course.courseid)"
  >
 
    <v-card-title>
      <p>
        <span class="font-weight-light">{{course.title}} 
          <span
            v-if="course.moderator"
            class="font-weight-light"
          > [{{course.courseid}}]</span>
        </span><br />
        <span class="caption">By {{course.publisher.name}}</span> 
        </p>
    </v-card-title>
 
    <v-spacer></v-spacer>
    <!--<v-card-text
    height="100%"
    >
      
      <v-progress-linear
        v-model="progress"
        :color="progressColor"
      >
      </v-progress-linear>     
      {{progress}}% complete
    </v-card-text> -->

    <v-card-actions
      v-if="(course.coursestatusid == 1) || course.moderator"
    >
      <v-spacer></v-spacer>
        <v-menu
          v-if="course.moderator"
          offset-y
        >

          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"              
              style="cursor: pointer;"
            >mdi-dots-vertical</v-icon>&nbsp;
          </template>

          <v-list>

            <v-list-item 
              @click="renameCourse(course.courseid)" 
              style="cursor: pointer;"
            >
              <v-list-item-title>Rename</v-list-item-title>
            </v-list-item>

            <v-list-item 
              @click="duplicateCourse(course.courseid)" 
              style="cursor: pointer;"
            >
              <v-list-item-title>Duplicate</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item 
              @click="deleteCourse(course.courseid)" 
              style="cursor: pointer;"
            >
              <v-list-item-title><span style="color: #aa4444;">Delete</span></v-list-item-title>
            </v-list-item>

          </v-list>

        </v-menu>            
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'CourseCard',

    data: () => ({
      progressColor: 'light-green',      
    }),

    props: {    

      course: {
        type: Object,
        default: null,
        required: true
      },

    },

    computed: {

      progress() {
        return this.progressCalc();
      }

    },

    methods: {

      deleteCourse(courseId) {
        if (confirm('Delete course?') == true) {
          this.$emit('deleteCourse', courseId);
        }
      },

      duplicateCourse(courseId) {
        this.$emit('duplicateCourse', courseId);
      },

      openCourse(courseId) { 
        this.$store.state.tabIndex = 0;
        this.$store.state.courseTitle = this.course.title;
        this.$store.state.publisherName = this.course.publisher.name;        
        this.$router.push('course/' + courseId)        
      },      

      progressCalc() {
        var percentage;

        if (this.course.totallessons > 0) {
          percentage = Math.floor((this.course.completedlessons / this.course.totallessons) * 100);        
        } else {
          percentage = 100;
        }        

        if (percentage >= 100) {
          this.progressColor = 'blue';
        } else {
          this.progressColor = 'green';
        } 

        return percentage;
      },

      renameCourse(courseId) {
        let title = prompt("New course title", this.course.title);
        if (!(title == null || title == "")) {
          this.$emit('renameCourse', courseId, title);
        }
      },

    }

  }
</script>
